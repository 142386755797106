<template>
  <div>
    <CRow>
      <CCol sm="12">
        <InvoicesTable
          :items="invoices"
          hover
          striped
          border
          small
          fixed
          caption="Facturas"
          @refresh="refreshItems"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import InvoicesTable from '../components/invoices/InvoicesTable.vue'
import ws from '../services/invoices';
import store from '../store'
 
export default {
  name: 'Invoices',
  components: { InvoicesTable },
  data: function () {
		return {
            invoices: []
        }
  },
  mounted: async function() {
      
  },
  methods: {
    async refreshItems (tab,filters = {}){
      this.loading();
      let response = await ws.get(tab,filters); 
      
      if(response.type == "success"){
        this.invoices = response.data;
      }
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
