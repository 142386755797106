<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="openLoadXmlModal"
    >
    <CForm>
        <CRow>
          <CCol md="12">
            <form id="xml_form" method="POST" enctype="multipart/form-data">
              <CInput
                label="Subir Xml:"
                :isValid="checkIfValid('xml')"
                :lazy="false"
                placeholder=""
                type="file"
                :value.sync="$v.form.xml.$model"
                name="xml"
                id="xml"
                invalidFeedback="Este campo es requerido."
                autocomplete="xml"
              />
            </form>
          </CCol>
        </CRow>
      </CForm>
      <template #footer>
        <CButton @click="closeModal" color="secondary">Cancelar</CButton>
        <CButton @click="loadXml" :color="color">Aceptar</CButton>
      </template>
    </CModal>
</template>

<script>
import store from '../../../store';
import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, integer, email} from "vuelidate/lib/validators"
import ws from '../../../services/invoices';

export default { 
  name: 'LoadXmlModal',
  props: {
    type: ''
  },  
  data () {
    return {
      openLoadXmlModal: false,
      title: "Cargar CFDI emitido en otro Sistema", 
      color: "info",
      submitted: false,
      form: this.getEmptyForm(),
    }
  },
  mounted: async function() {

  },
  methods: {
    openModal () {
      this.openLoadXmlModal = true;
      this.submitted = false;
      this.form = this.getEmptyForm();
    },
    async loadXml () {

      this.$v.$touch();
      this.loading();
      if (this.isValid) {
        this.submitted = true;        
        let form = document.getElementById("xml_form");
        let formData = new FormData(form);

        let response = await ws.loadXml(formData);

        if(response.type == "success"){
          this.$emit("store");
        }        
        this.showToast(response.type, response.message);
      }
      this.loaded();        
      this.closeModal();
    },
    closeModal () {
      this.openLoadXmlModal = false;
      this.form = this.getEmptyForm();
      this.submitted = false;
      this.$v.$reset()
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid)
    },
    getEmptyForm () {
      return {
          xml: "",
      }
    },        
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },  
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },      
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      xml: {
        required,
      },
    }
  },  
}
</script>