<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="paymentModal"
    >   
        <CForm>
          <CRow>
            <CCol md="8">
              <CInput
                label="Cliente:"
                :lazy="false"
                :value.sync="$v.form.name.$model"
                :isValid="checkIfValid('name')"
                placeholder=""
                autocomplete="name"
                disabled="1"
                invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
              />
            </CCol>
            <CCol md="4">
              <div role="group" class="form-group">
                <label> Fecha:</label>
                <v-date-picker
                  class="date-picker"
                  v-model="$v.form.date.$model"
                  :isValid="checkIfValid('date')"
                  locale="es-MX" size="sm"
                  :maxDate="optionsDate.maxDate"
                />
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="4">
              <CSelect
                label="Forma de Pago: "
                :isValid="checkIfValid('payment_form')"
                :value.sync="$v.form.payment_form.$model"
                :options="paymentFormsOptions"
              />
            </CCol>
            <CCol md="4">
              <CInput
                label="No. Operación:"
                :lazy="false"
                :value.sync="$v.form.no_operation.$model"
                :isValid="checkIfValid('no_operation')"
                placeholder=""
                autocomplete="no_operation"
                invalidFeedback="Este campo debe ser un valor numérico."
              />
            </CCol>
            <CCol md="4">
              <CInput
                label="Monto del Pago:"
                :lazy="false"
                :value.sync="$v.form.amount.$model"
                :isValid="checkIfValid('amount')"
                placeholder=""
                autocomplete="amount"
                invalidFeedback="Este campo debe ser un valor numérico."
              />
            </CCol>
          </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" color="success">Registrar Pago</CButton>
          <CButton @click="confirmCFDI" :color="color">Registrar Pago con CFDI</CButton>
        </template>

        <ConfirmModal ref="confirmModal" @confirm="storeCFDI()"></ConfirmModal>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, decimal, minValue} from "vuelidate/lib/validators"
import { DatePicker } from 'v-calendar'
import ConfirmModal from '../../global/ConfirmModal.vue'

import general_ws from '../../../services/general';
import payments_ws from '../../../services/payments';
import store from '../../../store'

import moment from 'moment';
import moment_timezone from 'moment-timezone'

moment.tz.setDefault('America/Mexico_city')

let today = new moment();
today = new moment(today.format("YYYY-MM-DD HH:m:ss"));
let today2 = new moment(today.format("YYYY-MM-DD HH:m:ss")).subtract(1, 'month');

export default {
  name: 'PaymentModal',
  props: {

  },
  components: {
    'v-date-picker': DatePicker, ConfirmModal
  },
  data () {
    return {
      paymentModal: false,
      title: "Pagar Factura",
      color: "info",
      isEdit: false,
      optionsDate: {
        maxDate: today.toDate(),
        minDate: today2.toDate()
      },
      form: this.getEmptyForm(),
      submitted: false,
      payment_forms: [],
      paymentFormsOptions: [],
    }
  },
  mounted: async function() {
    let response = await general_ws.getPaymentForms(); 

    if(response.type == "success"){
      this.payment_forms = response.data;
    }

    this.paymentFormsOptions = await this.$parseSelectOptionsOrdered(this.payment_forms, "code", "code:name");
  },
  methods: {
    storeModal (item) {
      this.paymentModal = true;
      this.color = "info";
      this.title = 'Pagar Factura '+item.serial+""+item.folio;
      this.isEdit = false;
      this.submitted = false;

      this.form = {
          id: 0,
          invoice_id: item.id,
          customer_id: item.customer_id,
          date: today.toDate(),
          name: item.customer_id > 0 ? item.customer_name : "PÚBLICO EN GENERAL",
          amount: this.$options.filters.decimal(item.balance),
          payment_form: item.payment_form == "99" ? "03" : item.payment_form,
          payment_method: item.payment_method,
          no_operation: '',
          currency: item.currency,
          exchange_rate: item.exchange_rate
      }
    },
    async store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        //Registrar Pago
        let response = await payments_ws.simplePayment(this.form); 

        if(response.type == "success"){
          this.form.id = response.data.id;
          this.$emit("store", this.form );

          this.closeModal();
        }

        this.showToast(response.type, response.message);
        
      }
    },
    async confirmCFDI () {
      if(this.form.payment_method == "PUE"){
        this.$refs.confirmModal.show("warning","Confirmación de Pago con CFDI", "La factura que deseas pagar tiene método de pago PUE y no es obligatorio emitir un complemento de pago.", "¿Estás seguro que deseas emitir un complemento de pago?");
      }
      else{
        this.storeCFDI();
      }
    },
    async storeCFDI () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        //Registrar Pago
        let response = await payments_ws.simplePaymentCFDI(this.form); 

        if(response.type == "success"){
          this.$emit("store", response.data ); 

          this.closeModal();
        }

        this.showToast(response.type, response.message);
        
      }
    },
    closeModal () {
      this.paymentModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          invoice_id: '',
          customer_id: '',
          date: today.toDate(),
          name: '',
          amount: '0.00',
          payment_form: '01',
          no_operation: '',
          currency: 'MXN',
          exchange_rate: 1.0000
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid)
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      date: {
        required
      },
      name: {
        required
      },
      amount: {
        required,
        decimal
      },
      payment_form: {
        required
      },
      no_operation:{
        
      }
    }
  },
}
</script>